import React from 'react';
import CTAButtons from '../../CTAButtons';
import './styles.scss';

const BannerTemp = (props) => {
    return (
        <div className='virtualBanner virtualCoffeeBanner'>
            {props.data.map((data, index) => {
                return (
                    <div className='virtualBannerInner' key={index}>
                        <div className='virtualBannerLeft'>
                            <h1>{data.Header}</h1>
                            <p>{data.Desc}</p>
                            <CTAButtons />
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default BannerTemp;
