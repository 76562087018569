import React from 'react';
import './styles.scss';

const VCSteps = (props) => {
    return (
        <div className='vCStepsListWrap'>
            {props.data.map((data, index) => {
                return (
                    <ul className='vCStepsList' key={index}>
                        <li>
                            <img data-src={data.Image} src={data.Image} alt='' />
                            {data.List}
                        </li>
                    </ul>
                );
            })}
        </div>
    );
};

export default VCSteps;
