import React from 'react';
import './styles.scss';
import Bounce from 'react-reveal/Bounce';
import { getUtmParams } from '../../../util/getUtmFromUrl';

const slackAddUrl = process.env.GATSBY_TRIVIA_SLACK_ADD_URL;
const teamsAddUrl = process.env.GATSBY_TRIVIA_MS_TEAMS_URL;

const Index = () => {
    const [utm, setUtm] = React.useState('');
    React.useEffect(() => {
        setUtm(getUtmParams());
    }, []);

    return (
        <Bounce top cascade>
            <div className='buttons'>
                <button
                    id='TRIVIA-SLACK-INSTALL'
                    onClick={() => window.open(encodeURI(`${slackAddUrl}${utm}`), '_blank')}
                >
                    <img
                        src={'https://sw-assets.gumlet.io/common/slack.svg'}
                        data-src={'https://sw-assets.gumlet.io/common/slack.svg'}
                        alt={'slack'}
                    />
                    Add to Slack
                </button>
                <button
                    id='TRIVIA-TEAMS-INSTALL'
                    onClick={() => window.open(encodeURI(`${teamsAddUrl}${utm}`), '_blank')}
                >
                    <img
                        src={'https://sw-assets.gumlet.io/common/ms-teams.svg'}
                        data-src={'https://sw-assets.gumlet.io/common/ms-teams.svg'}
                        alt={'teams'}
                    />
                    Add to Teams
                </button>
            </div>
        </Bounce>
    );
};

export default Index;
