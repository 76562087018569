import React from 'react';
import './styles.scss';
import PlatformDropdown from '../Dropdown';
import StartModal from '../../../components/trivia/StartModal';
import { Link } from 'gatsby';

class Navbar extends React.Component {
    state = {
        displayLink: false,
        addTo: false,
        onCta: false,
        utm: '',
        show: false,
        gotoPage: 'Home',
        login: false,
    };
    render() {
        return (
            <div className='navbar'>
                <Link to={'/trivia/'}>
                    <img
                        src={'https://sw-assets.gumlet.io/trivia/logos/trivia-logo-black.svg'}
                        data-src={'https://sw-assets.gumlet.io/trivia/logos/trivia-logo-black.svg'}
                        alt={'Trivia logo'}
                        width={80}
                        height={35}
                    />
                </Link>
                <div className='right'>
                    <div className='links'>
                        <span>
                            <span>Platforms</span>{' '}
                            <img
                                data-src={'https://sw-assets.gumlet.io/trivia/misc/down.svg'}
                                src={'https://sw-assets.gumlet.io/trivia/misc/down.svg'}
                                alt={'down'}
                            />
                            <div className='dropdown'>
                                <Link to={'/trivia/slack/'}>
                                    <img
                                        src={'https://sw-assets.gumlet.io/common/slack.svg'}
                                        data-src={'https://sw-assets.gumlet.io/common/slack.svg'}
                                        alt={'slack'}
                                    />
                                    Slack
                                </Link>
                                <Link to={'/trivia/microsoft-teams/'}>
                                    <img
                                        src={'https://sw-assets.gumlet.io/common/ms-teams.svg'}
                                        data-src={'https://sw-assets.gumlet.io/common/ms-teams.svg'}
                                        alt={'microsoft teams'}
                                    />
                                    MS Teams
                                </Link>
                            </div>
                        </span>
                        <span>
                            <span>Games</span>{' '}
                            <img
                                data-src={'https://sw-assets.gumlet.io/trivia/misc/down.svg'}
                                src={'https://sw-assets.gumlet.io/trivia/misc/down.svg'}
                                alt={'down'}
                            />
                            <div className='dropdown'>
                                <Link to={'/trivia/games/'}>Games</Link>
                                <Link to={'/trivia/virtual-water-cooler/'}>
                                    Virtual Water Cooler
                                    <img
                                        data-src={'https://sw-assets.gumlet.io/trivia/misc/new.svg'}
                                        src={'https://sw-assets.gumlet.io/trivia/misc/new.svg'}
                                        alt={'new'}
                                        className='new'
                                    />
                                </Link>
                                <Link to={'/trivia/pictionary-online/'}>
                                    Pictionary
                                    <img
                                        data-src={'https://sw-assets.gumlet.io/trivia/misc/new.svg'}
                                        src={'https://sw-assets.gumlet.io/trivia/misc/new.svg'}
                                        alt={'new'}
                                        className='new'
                                    />
                                </Link>
                                <Link to={'/trivia/internal-branding/'}>
                                    Internal Branding Quizzes
                                    <img
                                        data-src={'https://sw-assets.gumlet.io/trivia/misc/new.svg'}
                                        src={'https://sw-assets.gumlet.io/trivia/misc/new.svg'}
                                        alt={'new'}
                                        className='new'
                                    />
                                </Link>
                                <Link to={'/trivia/virtual-coffee/'}>
                                    VirtualCoffee
                                    <img
                                        data-src={'https://sw-assets.gumlet.io/trivia/misc/new.svg'}
                                        src={'https://sw-assets.gumlet.io/trivia/misc/new.svg'}
                                        alt={'new'}
                                        className='new'
                                    />
                                </Link>
                            </div>
                        </span>
                        <span>
                            <Link to={'/trivia/pricing/'}>Pricing</Link>
                        </span>
                        <span>
                            <span>Resources</span>
                            <img
                                data-src={'https://sw-assets.gumlet.io/trivia/misc/down.svg'}
                                src={'https://sw-assets.gumlet.io/trivia/misc/down.svg'}
                                alt={'down'}
                            />
                            <div className='dropdown'>
                                <a
                                    href={'https://www.springworks.in/blog/category/trivia/'}
                                    target='_blank'
                                    rel='noreferrer noopener'
                                >
                                    Blog
                                </a>
                                <a
                                    href='https://support.springworks.in/portal/en/kb/trivia'
                                    target='_blank'
                                    rel='noreferrer noopener'
                                >
                                    How to Guide
                                </a>
                                <a
                                    href='https://springworks.tawk.help/category/trivia-knowledge-base/support-videos'
                                    target='_blank'
                                    rel='noreferrer noopener'
                                >
                                    Videos
                                </a>
                                <Link to={'https://support.springworks.in/portal/en/kb/trivia'}>FAQ</Link>
                            </div>
                        </span>
                        <span>
                            <span>Solutions</span>
                            <img
                                data-src={'https://sw-assets.gumlet.io/trivia/misc/down.svg'}
                                src={'https://sw-assets.gumlet.io/trivia/misc/down.svg'}
                                alt={'down'}
                            />
                            <div className='dropdown'>
                                <a href='https://www.springworks.in/trivia/employee-onboarding/'>
                                    New Employee Onboarding
                                </a>
                                <a href='https://www.springworks.in/trivia/employee-engagement/'>Employee Engagement</a>
                                <a href='https://www.springworks.in/trivia/remote-teams/'>Remote Teams</a>
                                <a href='https://www.springworks.in/trivia/virtual-happy-hours/'>Virtual Happy Hour</a>
                            </div>
                        </span>
                        <span>
                            <Link to={'/trivia/customers/'}>Success Stories</Link>
                        </span>
                    </div>
                    <div className='buttons'>
                        <span
                            className='login'
                            onKeyDown={(e) => {
                                if (e.keyCode === 13) this.setState({ show: true, login: true });
                            }}
                            onClick={() => this.setState({ show: true, login: true })}
                            tabIndex={'0'}
                            role={'button'}
                        >
                            Login
                        </span>
                        <button className='add' onClick={() => this.setState({ show: true, login: false })}>
                            Add <span>Trivia</span>
                        </button>
                    </div>
                    <div
                        className='hamburger'
                        onClick={() => this.setState({ displayLink: true })}
                        onKeyDown={(e) => {
                            if (e.keyCode === 13) this.setState({ displayLink: true });
                        }}
                        tabIndex={'0'}
                        role={'button'}
                    >
                        <img
                            data-src={'https://sw-assets.gumlet.io/trivia/misc/menuListIcon.svg'}
                            src={'https://sw-assets.gumlet.io/trivia/misc/menuListIcon.svg'}
                            alt={'menu'}
                        />
                    </div>

                    <StartModal
                        login={this.state.login}
                        show={this.state.show}
                        hide={() => this.setState({ show: false })}
                        slackAddUrl={process.env.GATSBY_TRIVIA_SLACK_ADD_URL}
                        teamsAddUrl={process.env.GATSBY_TRIVIA_MS_TEAMS_URL}
                        slackLoginUrl={process.env.GATSBY_TRIVIA_SLACK_LOGIN_URL}
                        teamsLoginUrl={process.env.GATSBY_TRIVIA_MS_TEAMS_LOGIN_URL}
                        webLoginUrl={process.env.GATSBY_TRIVIA_WEB_LOGIN_URL}
                        webSignupUrl={process.env.GATSBY_TRIVIA_WEB_SIGNUP_URL}
                    />
                </div>
                {this.state.displayLink ? (
                    <div className='mobileLinkCtr'>
                        <div className='innerCtrHeader'>
                            <div
                                className='crossMenuIcon'
                                onClick={() => this.setState({ displayLink: false })}
                                onKeyDown={(e) => {
                                    if (e.keyCode === 13) this.setState({ displayLink: false });
                                }}
                                tabIndex={'0'}
                                role={'button'}
                            >
                                <img
                                    data-src={'https://sw-assets.gumlet.io/trivia/misc/crossMenuIcon.svg'}
                                    src={'https://sw-assets.gumlet.io/trivia/misc/crossMenuIcon.svg'}
                                    alt={'close'}
                                />
                            </div>

                            <div className='pageMenu'>
                                <PlatformDropdown name='Platforms'>
                                    <Link to={'/trivia/slack/'}>
                                        <img
                                            src={'https://sw-assets.gumlet.io/common/slack.svg'}
                                            data-src={'https://sw-assets.gumlet.io/common/slack.svg'}
                                            alt={'slack'}
                                        />
                                        Slack
                                    </Link>
                                    <Link to={'/trivia/microsoft-teams/'}>
                                        <img
                                            src={'https://sw-assets.gumlet.io/common/ms-teams.svg'}
                                            data-src={'https://sw-assets.gumlet.io/common/ms-teams.svg'}
                                            alt={'microsoft teams'}
                                        />
                                        MS Teams
                                    </Link>
                                </PlatformDropdown>
                                <PlatformDropdown name='Games'>
                                    <Link to={'/trivia/games/'}>Games</Link>
                                    <Link to={'/trivia/virtual-water-cooler/'}>
                                        Virtual Water Cooler
                                        <img
                                            data-src={'https://sw-assets.gumlet.io/trivia/misc/new.svg'}
                                            src={'https://sw-assets.gumlet.io/trivia/misc/new.svg'}
                                            alt={'new'}
                                            className='new'
                                        />
                                    </Link>
                                    <Link to={'/trivia/pictionary-online/'}>
                                        Pictionary
                                        <img
                                            data-src={'https://sw-assets.gumlet.io/trivia/misc/new.svg'}
                                            src={'https://sw-assets.gumlet.io/trivia/misc/new.svg'}
                                            alt={'new'}
                                            className='new'
                                        />
                                    </Link>
                                </PlatformDropdown>
                                <Link to={'/trivia/pricing/'}>Pricing</Link>
                                <PlatformDropdown name='Resources'>
                                    <a
                                        href={'https://www.springworks.in/blog/category/trivia/'}
                                        target='_blank'
                                        rel='noreferrer noopener'
                                    >
                                        Blog
                                    </a>
                                    <a
                                        href='https://springworks.tawk.help/category/trivia-knowledge-base'
                                        target='_blank'
                                        rel='noreferrer noopener'
                                    >
                                        User Guide
                                    </a>
                                    <a
                                        href='https://springworks.tawk.help/category/trivia-knowledge-base/support-videos'
                                        target='_blank'
                                        rel='noreferrer noopener'
                                    >
                                        Videos
                                    </a>
                                    <Link to={'/trivia/faq/'}>FAQ</Link>
                                </PlatformDropdown>
                                <PlatformDropdown name='Solutions'>
                                    <a href='https://www.springworks.in/trivia/employee-onboarding/'>
                                        New Employee Onboarding
                                    </a>
                                    <a href='https://www.springworks.in/trivia/employee-engagement/'>
                                        Employee Engagement
                                    </a>
                                    <a href='https://www.springworks.in/trivia/remote-teams/'>Remote Teams</a>
                                    <a href='https://www.springworks.in/trivia/virtual-happy-hours/'>
                                        Virtual Happy Hour
                                    </a>
                                </PlatformDropdown>
                                <Link to={'/trivia/customers/'}>Success Stories</Link>
                            </div>

                            <div className='hamburger-logins'>
                                <button onClick={() => this.setState({ show: true, login: true })}>
                                    <b>Login</b>
                                </button>
                                <button
                                    id='ADD-TRIVIA-NOW-HEADER'
                                    onClick={() => this.setState({ show: true, login: false })}
                                >
                                    Add <b>Trivia</b>
                                </button>
                            </div>
                        </div>
                    </div>
                ) : null}
            </div>
        );
    }
}

export default Navbar;
