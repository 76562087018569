import React from 'react';
import './styles.scss';
import Slider from 'react-slick';

const Image = ({ image }) => (
    <img
        src={`https://sw-assets.gumlet.io/trivia/customers/${image}?blur=30`}
        data-src={`https://sw-assets.gumlet.io/trivia/customers/${image}`}
        alt={image.split('.')[0]}
    />
);

const Users = () => {
    const settings = {
        dots: false,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 2,
        autoplay: true,
        arrows: false,
        autoplaySpeed: 2000,
    };
    const users = [
        'Quora-logo.svg',
        'NBA-logo.svg',
        'EA-logo.svg',
        'Olx-logo.svg',
        'Shopify-logo.svg',
        'science-small-logo.png',
    ];
    return (
        <div className='users'>
            <p className='p1'>
                400,000+ employees from 10,000+ organizations across the globe rely on Trivia for team bonding
            </p>
            <div className='list lg'>
                {users.map((image) => (
                    <Image key={image} image={image} />
                ))}
            </div>
            <Slider {...settings}>
                {users.map((image) => (
                    <Image key={image} image={image} />
                ))}
            </Slider>
        </div>
    );
};

export default Users;
