import React from 'react';
import './styles.scss';
import { Bounce, Fade } from 'react-reveal';
import { getUtmParams } from '../../../../util/getUtmFromUrl';

const slackAddUrl = process.env.GATSBY_TRIVIA_SLACK_ADD_URL;

const avatarsBaseUrl = 'https://sw-assets.gumlet.io/trivia/avatars/';

const avatars = [1, 2, 3, 4, 5, 6, 7, 8];

function User({ name, alt }) {
    return (
        <span>
            <img src={`${avatarsBaseUrl}${name}?blur=30`} data-src={`${avatarsBaseUrl}${name}`} alt={alt} />
        </span>
    );
}

class TriviaUsers extends React.Component {
    state = {
        utm: '',
    };

    componentDidMount() {
        const utm = getUtmParams();
        this.setState({ utm });
    }

    render() {
        const { utm } = this.state;
        return (
            <div className='virtualUsersWrap'>
                <div className='virtualContainer'>
                    <div className='virtualUsers'>
                        <Bounce left cascade>
                            <div className='virtualUsersLeft'>
                                <div className='virtualUsersAvatar'>
                                    {avatars.slice(0, 4).map((a, i) => (
                                        <User key={i} name={`${a}.svg`} alt={'user'} />
                                    ))}
                                </div>
                                <div className='virtualUsersAvatar'>
                                    {avatars.slice(4).map((a, i) => (
                                        <User key={i} name={`${a}.svg`} alt={'user'} />
                                    ))}
                                </div>
                            </div>
                        </Bounce>
                        <Fade top>
                            <div className='virtualUsersRight'>
                                <h4>{this.props.content ?? `Introduce teammates. \nConnect your people. `}</h4>
                                <div className='virtualHeaderCTA'>
                                    <button
                                        id='TRIVIA-SLACK-INSTALL'
                                        onClick={() => window.open(encodeURI(`${slackAddUrl}${utm}`), '_blank')}
                                    >
                                        <img
                                            src={'https://sw-assets.gumlet.io/common/slack.svg'}
                                            data-src={'https://sw-assets.gumlet.io/common/slack.svg'}
                                            alt={'slack'}
                                        />
                                        Add Trivia to Slack
                                    </button>
                                </div>
                            </div>
                        </Fade>
                    </div>
                </div>
            </div>
        );
    }
}

export default TriviaUsers;
