import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import './styles.scss';

const VCSteps = (props) => {
    return (
        <>
            <div className='triviaContainer'>
                <div className='vCStepsWrap'>
                    {props.data.map((data, index) => {
                        return (
                            <div className='vCStepsCard' key={index}>
                                <img data-src={data.Image} src={data.Image} alt='' />
                                <h5>{data.Header}</h5>
                                <p>{data.Desc}</p>
                            </div>
                        );
                    })}
                </div>
                <div className='vCStepsWrapMob'>
                    <Carousel>
                        {props.data.map((data, index) => {
                            return (
                                <Carousel.Item key={index} interval={3000}>
                                    <div className='vCStepsCard'>
                                        <img data-src={data.Image} src={data.Image} alt='' />
                                        <h5>{data.Header}</h5>
                                        <p>{data.Desc}</p>
                                    </div>
                                </Carousel.Item>
                            );
                        })}
                    </Carousel>
                </div>
            </div>
        </>
    );
};

export default VCSteps;
