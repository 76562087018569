export const DataConstant = {
    VirtualBond: [
        {
            h3: 'Pictionary taken virtual',
            p: `The classic game, without any preparation.`,
            imageDesc: [
                {
                    Image: 'https://sw-assets.gumlet.io/trivia/virtual-team-games-on-web/pictionary.svg',
                    altTxt: 'Virtual Pictionary Games for Remote teams',
                    h4: 'No pens, whiteboards \nand dusters. No mess.',
                    Desc:
                        'Right from digital whiteboards to moderators to score calculators, Trivia takes care of everything.',
                },
                {
                    Image: 'https://sw-assets.gumlet.io/trivia/virtual-team-games-on-web/feels.svg',
                    altTxt: 'Play Pictionary Online with peers at work',
                    h4: 'Feels as good as \nplaying in person.',
                    Desc:
                        "Pictionary brings out the funny, artistic side of your colleagues you don't get to see usually. On top of that you get to see them make hilarious guesses!",
                },
            ],
        },
        {
            h3: 'Build stronger bonds at work',
            p: `Encourage participation, boost camaraderie and enliven your virtual happy hours with Trivia.`,
            imageDesc: [
                {
                    Image: 'https://sw-assets.gumlet.io/trivia/virtual-team-games-on-web/break.svg',
                    altTxt: 'Team Building Activities for Employees',
                    h4: 'Take a break. Unwind \nwith your team.',
                    Desc:
                        'Bring your team together for quick 5-minute breaks. Bond over fun and social games. Get to know each other every time you play.',
                },
                {
                    Image: 'https://sw-assets.gumlet.io/trivia/virtual-team-games-on-web/stir.svg',
                    altTxt: 'Corporate Team Building Games for Remote Work',
                    h4: 'Stir up some friendly competition.',
                    Desc:
                        "Trivia comes integrated with an organization-wide, dynamic leaderboard. So if you're a trivia fanatic, you will be remembered.",
                },
            ],
        },
    ],

    VirtualHappyHoursBanner: [
        {
            Header: 'Make virtual happy \nhours happier',
            Desc:
                'Sick of getting flooded with Zoom happy hours? We’ve got you! Host fun, engaging, and memorable virtual happy hours on Slack and Microsoft Teams with Trivia.',
            Illustration: 'https://sw-assets.gumlet.io/trivia/virtual-happy-hours/illustration.svg',
        },
    ],
    VirtualCoffeeBanner: [
        {
            Header: 'Engage beyond your screen. \nFoster meaningful connections.',
            Desc:
                'Introduce teammates 1v1, skip the tension of awkward small \ntalk and spark casual conversations - wherever you are.',
        },
    ],

    RemoteTeamsBanner: [
        {
            Header: 'Bond with your team \neven while apart',
            Desc: "With Trivia's suite of Games, Water Cooler and VirtualCoffee.",
            Illustration: 'https://sw-assets.gumlet.io/trivia/remote-teams/remote-bond.png',
        },
    ],

    NewEmployeeOnboarding: [
        {
            Header: 'Break the ice. \nBuild new bonds.',
            Desc:
                'Help fresh hires connect and socialize with their new teammates right from day one. In short, “Tim from Marketing” becomes “Tim who loves burgers, backpacking and has dreams of building a canoe.',
            Illustration: 'https://sw-assets.gumlet.io/trivia/employee-onboarding/break-the-ice.png',
        },
    ],

    EmployeeEngagement: [
        {
            Header: '10x your team \nbonding, instantly',
            Desc: "By engaging your teams over Trivia's suite of Games, Water Cooler and VirtualCoffee",
            Illustration: 'https://sw-assets.gumlet.io/trivia/employee-engagement/team-bonding.png',
        },
    ],

    TeamPercent: [
        {
            Header: '10X',
            Desc: 'Team bondings',
        },
        {
            Header: '250%',
            Desc: 'Increase in productivity',
        },
        {
            Header: '5X',
            Desc: 'Faster peer-to-peer connections',
        },
        {
            Header: '90%',
            Desc: 'Employees report a fresher, \nhappier, relaxed mindset',
        },
    ],

    MustTryCards: [
        {
            Image: 'https://sw-assets.gumlet.io/trivia/virtual-happy-hours/quizzes.svg',
            Header: 'Quizzes',
            Para: 'The usual suspect of the lot. It’s time to show off your knowledge repository.',
            SubHeader: 'Warning',
            SubText: '- Incredibly addictive.',
        },
        {
            Image: 'https://sw-assets.gumlet.io/trivia/virtual-happy-hours/charades.svg',
            Header: 'GIF Charades',
            Para:
                'Time to flex your movie-buff muscles. Inspect the GIF posted by Trivia, guess the TV show/movie, and sweep the scorecards.',
            SubHeader: 'Warning',
            SubText: "-  It's time to roast your colleagues",
        },
        {
            Image: 'https://sw-assets.gumlet.io/trivia/virtual-happy-hours/pictionary.svg',
            Header: 'Pictionary',
            Para: 'Digital equivalent of the famous board game.',
            SubHeader: 'Warning',
            SubText: '- With every  wrong guess you make, a laugh riot awaits you.',
        },
        {
            Image: 'https://sw-assets.gumlet.io/trivia/virtual-happy-hours/popular.svg',
            Header: '(Un)Popular Opinions',
            Para: 'Stir up some controversies by letting your opinions known.',
            SubHeader: 'Warning',
            SubText: '- Make new friends.',
        },
    ],
    virtualCoffee: [
        {
            Header: 'What is VirtualCoffee?',
            Para:
                'Just like in-office coffee rituals do more than keep you caffeinated, VirtualCoffee helps you recreate that coffee culture irrespective of your geography. ',
            Image: 'https://sw-assets.gumlet.io/trivia/virtual-happy-hours/create-schedule.svg',
        },
    ],
    VCList: [
        {
            Image: 'https://sw-assets.gumlet.io/trivia/virtual-coffee/enable.svg',
            List: 'Enable VirtualCoffee in a channel inside Slack',
        },
        {
            Image: 'https://sw-assets.gumlet.io/trivia/virtual-coffee/add.svg',
            List:
                'Add people to this channel. Once done, Trivia automatically introduces colleagues and prompts them to get to know each other better',
        },
        {
            Image: 'https://sw-assets.gumlet.io/trivia/virtual-coffee/chat.svg',
            List:
                'With conversation starters, encourage virtual catch-ups or even better - help them get on a call. This repeats every 2/4/12 weeks',
        },
    ],
    VCSteps: [
        {
            Image: 'https://sw-assets.gumlet.io/trivia/virtual-coffee/pair.svg',
            Header: 'Pair-up \nindividuals 1v1',
            Desc:
                'Trivia will select people from your Intro channel and will send a DM encouraging them to meet up for lunch, snacks or even a virtual coffee!',
        },
        {
            Image: 'https://sw-assets.gumlet.io/trivia/virtual-coffee/facilitate.svg',
            Header: 'Facilitate cross-team connections',
            Desc:
                'Break silos and spark conversations across distances or departments. Build trust and up the fun factor every time you catch up with peers. ',
        },
        {
            Image: 'https://sw-assets.gumlet.io/trivia/virtual-coffee/watch.svg',
            Header: 'Watch your connections grow',
            Desc:
                'Discover new things such as shared interests, hobbies and more. Make getting to know each other fun and foster meaningful connections.',
        },
    ],
    virtualCoffeeList: [
        {
            Icon: 'https://sw-assets.gumlet.io/trivia/virtual-coffee/enable.svg',
            desc: 'Enable VirtualCoffee in a channel inside Slack',
        },
        {
            Icon: 'https://sw-assets.gumlet.io/trivia/virtual-coffee/add.svg',
            desc:
                'Add people to this channel. Once done, Trivia automatically introduces \ncolleagues and prompts them to get to know each other better',
        },
        {
            Icon: 'https://sw-assets.gumlet.io/trivia/virtual-coffee/chat.svg',
            desc:
                'With conversation starters, encourage virtual catch-ups or even better - help \nthem get on a call. This repeats every 2/4/12 weeks',
        },
    ],
    SectionContent: [
        {
            Header: 'Never miss out on the fun \nwith Auto-Schedule',
            Para:
                'Trivia Tuesdays? Weekly game nights? TGIFs? Just schedule them on Trivia in a channel of your preference. Trivia takes care of the rest from pre-game notifications to announcing winners.',
            Image: 'https://sw-assets.gumlet.io/trivia/virtual-happy-hours/create-schedule.svg',
        },
        {
            Header: 'Works great for \nicebreakers',
            Para:
                'Recently hired new folks? No problem! Gather them on Trivia, help them break the ice, and build camaraderie with your team - right from day one.',
            Image: 'https://sw-assets.gumlet.io/trivia/virtual-happy-hours/icebreakers.svg',
        },
        {
            Header: 'Stir up friendly \ncompetitions and banter',
            Para:
                'Rank individuals based on their scores with Leaderboards. Spice up the competition and encourage participation among your people. Remember, once you sweep the scorecards, you’ll have a lot of inside jokes and friendly banter to share. That’s Trivia for you!',
            Image: 'https://sw-assets.gumlet.io/trivia/virtual-happy-hours/stir.svg',
        },
    ],
    RemoteTeamsSecContent: [
        {
            Header: 'No office? No problem!',
            Para:
                'For remote teams, the sense of belonging and togetherness helps build a healthy and happy workplace. Rightly so, Trivia helps you stay connected through Quizzes, Water Cooler, and VirtualCoffee.',
            Image: 'https://sw-assets.gumlet.io/trivia/remote-teams/no-office.png',
        },
        {
            Header: 'Socialize across geographies \nover fun games',
            Para:
                'Bond over Trivia’s suite of Games. Spark conversations, build camaraderie, and discover new things about your peers every time you play it.',
            Image: 'https://sw-assets.gumlet.io/trivia/remote-teams/socialize.png',
        },
        {
            Header: 'Take your Water Cooler \ntalks virtual',
            Para:
                'Post topics in the channel of your choice and kick-start discussions. Recreate those in-office rituals even while working remotely with Trivia’s Water Cooler.',
            Image: 'https://sw-assets.gumlet.io/trivia/remote-teams/water-cooler.png',
        },
        {
            Header: 'Make meaningful \nconnections with \nVirtualCoffee',
            Para:
                'Introduce teammates and help them socialize effortlessly. Build a sense of collaboration and community even if your people are distributed across geography.',
            Image: 'https://sw-assets.gumlet.io/trivia/remote-teams/virtual-coffee.png',
        },
        {
            Header: 'Banish isolation. \nFoster Collaboration. ',
            Para:
                'You may be missing your office. But you should not miss the fun of getting together with your co-workers. Eliminate isolation, get together as a team, and build bonds with your people effortlessly.',
            Image: 'https://sw-assets.gumlet.io/trivia/remote-teams/banish.png',
        },
    ],

    NewEmployeeContent: [
        {
            Header: 'Give the warmth \nthey need the fun way',
            Para:
                'Involve them in games like Quizzes, Pictionary, Gotcha, and more. Create a fun, memorable, and lively atmosphere for them to settle in effortlessly.',
            Image: 'https://sw-assets.gumlet.io/trivia/employee-onboarding/give-the-warmth.png',
        },
        {
            Header: 'Celebrate birthdays with \nCustom Quizzes',
            Para:
                'Make birthdays more special by launching a personalized quiz with questions about the new hires. Encourage participation and help them to get to know each other better, one answer at a time.',
            Image: 'https://sw-assets.gumlet.io/trivia/employee-onboarding/celebrate-birthday.png',
        },
        {
            Header: 'Get social \nwith Water Cooler',
            Para:
                'Connect around the Water Cooler for a casual talk about hobbies, interests, and passions. Help your fresh hires adapt and settle in faster.',
            Image: 'https://sw-assets.gumlet.io/trivia/employee-onboarding/get-social.png',
        },
        {
            Header: 'Introduce them to \nnew buddies',
            Para:
                'Introduce them to new teammates, encourage frequent meetings, and help them get to know each other better.',
            Image: 'https://sw-assets.gumlet.io/trivia/employee-onboarding/buddies-via-virtual-coffee.png',
        },
        {
            Header: 'Nothing like fostering \nworkplace friendships',
            Para:
                'It is more than just about “getting along” because the bonds you build during your initial days mean a lot. Strong in-office social connections make people happier and physically healthier, which can ultimately translate into better work performance.',
            Image: 'https://sw-assets.gumlet.io/trivia/employee-onboarding/workplace-friendships.png',
        },
    ],

    EmployeeEngagementSec: [
        {
            Header: 'Catch-up with your team \nover fun Games',
            Para:
                'All work and no play… you know how it goes. Trivia’s suite of games brings some much-needed fun and frolic to your daily work. Take a break, choose a game and stay connected with your team.',
            Image: 'https://sw-assets.gumlet.io/trivia/employee-engagement/catch-up.png',
        },
        {
            Header: 'Make engagements happen through VirtualCoffee',
            Para: 'Introduce teammates, encourage frequent catch-ups and help them get to know each other.',
            Image: 'https://sw-assets.gumlet.io/trivia/employee-engagement/make-engagements.png',
        },
        {
            Header: 'Connect around the Water Cooler anywhere',
            Para:
                'Post prompts, inspire conversations, and recreate those office watercooler rituals right inside Slack and Microsoft Teams.',
            Image: 'https://sw-assets.gumlet.io/trivia/employee-engagement/connect-around.png',
        },
        {
            Header: 'Build a super-engaged \nworkforce',
            Para:
                'The effects of building a highly engaged workforce are numerous. From reduced turnover to improved productivity and efficiency, Trivia helps you build a cohesive unit effortlessly.',
            Image: 'https://sw-assets.gumlet.io/trivia/employee-engagement/super-engaged.png',
        },
    ],

    OrganizationCard: [
        {
            Image: 'https://sw-assets.gumlet.io/trivia/employee-engagement/Frame 3558.png',
            Desc:
                'Trivia games are a lot of fun. It gives my team a quick 5 mins break, brings everyone together, and share light moments despite our busy schedules.',
            name: 'Elle Park',
            designation: 'Executive Assistant',
            statement: 'Build camaraderie and fun at work',
            statementTwo: 'Improve employee relations',
            link: 'https://www.springworks.in/blog/science-inc-case-study/',
        },
        {
            Image: 'https://sw-assets.gumlet.io/trivia/employee-engagement/Frame 3557.png',
            Desc:
                'We use Trivia for remote ice-breakers. It has ensured the fresh hires connect with their new teammates by creating a shared sense of camaraderie and community right from day one.',
            name: 'Yashodhara',
            designation: 'Head of Marketing',
            statement: 'Get to know your team, better',
            statementTwo: 'Improve peer-peer connections',
            link: 'https://www.springworks.in/blog/trivia-at-skillenza/',
        },
        {
            Image: 'https://sw-assets.gumlet.io/trivia/employee-engagement/Frame 3556.png',
            Desc:
                'Trivia exactly showed us what we were missing. The more we played it, the better we got at building genuine relationships, trust, and personal bonds amongst my people.',
            name: 'Koshik',
            designation: 'Founder of Consenso Labs',
            statement: '150% increase in engagements',
            statementTwo: 'Strengthen personal connections',
            link: 'https://www.springworks.in/blog/how-top-companies-use-trivia/',
        },
    ],
};
