import React from 'react';
import VCList from '../VCList';
import { DataConstant } from '../../DataConstant';
import './styles.scss';

const VCSteps = (props) => {
    return (
        <>
            <div className='triviaContainer'>
                <div className='vCWhatWrap'>
                    <div className='vCWhatLeft'>
                        <h2>
                            What is <br />
                            VirtualCoffee?
                        </h2>
                        <div className='vCWhatRightMob'>
                            <img
                                data-src={
                                    'https://sw-assets.gumlet.io/trivia/virtual-coffee/virtual-coffee-illustration%20.svg'
                                }
                                src={
                                    'https://sw-assets.gumlet.io/trivia/virtual-coffee/virtual-coffee-illustration%20.svg'
                                }
                                alt=''
                            />
                        </div>
                        <p>
                            Just like in-office coffee rituals do more than keep you caffeinated, VirtualCoffee <br />
                            helps you recreate that coffee culture irrespective of your geography.{' '}
                        </p>
                        <VCList data={DataConstant.VCList} />
                    </div>
                    <div className='vCWhatRight'>
                        <img
                            data-src={
                                'https://sw-assets.gumlet.io/trivia/virtual-coffee/virtual-coffee-illustration%20.svg'
                            }
                            alt=''
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default VCSteps;
