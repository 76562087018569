import React, { useState } from 'react';
import './styles.scss';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { Accordion, Button, Card } from 'react-bootstrap';

const Example = (props) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const toggle = () => setDropdownOpen((prevState) => !prevState);

    return (
        <>
            <Dropdown isOpen={dropdownOpen} toggle={toggle} style={{ display: 'flex', alignItems: 'center' }}>
                <DropdownToggle>
                    {props.name}
                    <img
                        className='mob-arrow-nav'
                        src={'https://sw-assets.gumlet.io/trivia/misc/blue-down-arrow.svg'}
                        data-src={'https://sw-assets.gumlet.io/trivia/misc/blue-down-arrow.svg'}
                        alt={'down'}
                    />
                </DropdownToggle>

                <div className='DropdownMenuWrap'>
                    <DropdownMenu>
                        <DropdownItem href='/trivia/slack/'>
                            <span>
                                <img
                                    src={'https://sw-assets.gumlet.io/common/slack.svg'}
                                    data-src={'https://sw-assets.gumlet.io/common/slack.svg'}
                                    alt={'slack'}
                                />
                                Slack
                            </span>
                        </DropdownItem>

                        <DropdownItem href='/trivia/microsoft-teams/'>
                            <span>
                                <img
                                    src={'https://sw-assets.gumlet.io/common/ms-teams.svg'}
                                    data-src={'https://sw-assets.gumlet.io/common/ms-teams.svg'}
                                    alt={'microsoft teams'}
                                />
                                MS Teams
                            </span>
                        </DropdownItem>

                        <img
                            className='arrow-up'
                            src={'https://sw-assets.gumlet.io/trivia/misc/white-arrow-up.png?blur=30'}
                            data-src={'https://sw-assets.gumlet.io/trivia/misc/white-arrow-up.png'}
                            alt={'up'}
                        />
                    </DropdownMenu>
                </div>
            </Dropdown>

            <Accordion className='PlatformsAccording'>
                <Card>
                    <Card.Header>
                        <Accordion.Toggle as={Button} variant='link' eventKey='0'>
                            {props.name}
                            <img
                                className='mob-arrow-nav'
                                src={'https://sw-assets.gumlet.io/trivia/misc/blue-down-arrow.svg'}
                                data-src={'https://sw-assets.gumlet.io/trivia/misc/blue-down-arrow.svg'}
                                alt={'down'}
                            />
                        </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey='0'>
                        <Card.Body>{props.children}</Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
        </>
    );
};

export default Example;
